import { getValueFromLocalStorage } from "../../../utils/local-storage";
import permissions from "../../../utils/permissionConfig";

 const SIDEBAR_CONSTANTS = {
	VISITS: 'visits',
	SALES_VISIT: 'sales-visits',
	SALES_VISIT_DETAILS: 'sales-visit',
	VISIT_DETAILS: 'visit',
	LOANS: 'loans',
	CLOSURE_REQUESTS: 'closure-requests',
	SUPPORT_LOANS: 'support-loans',
	AGENTS: 'agents',
	USERS: 'users',
	LOAN_DETAILS: 'loan',
	TAKEOVER: 'takeover',
	DISBURSEMENT_CENTER: 'disbursement-centre',
	BRL_VISITS: 'brl-visits',
	BRL_LOANS: 'brl-loans',
	ELR_VISITS: 'elr-visits',
	COBORROWER: 'co-borrower',
	CONFIG: 'config',
	PLANS: 'plans',
	RATES: 'rates',
	PARTNERS: 'partners',
	GOLD_ORNAMENTS: 'manage-gold-ornament-types',
	PINCODES: 'manage-pincodes',
	LENDERS: 'lenders',
	GOLD_MARKINGS: 'gold-marking-types',
	OLD_OFFERS: 'old-offer',
	TAKEOVER_VISITS: 'takeover-visits',
	TAKEOVER_LOANS: 'takeover-loans',
	DISBURSEMENT_CENTER_LOANS: 'dc-loans',
	DISBURSEMENT_CENTER_VISITS: 'dc-visits',
	DISBURSEMENT_CENTER_DETAILS: 'dc-details',
	ORO_OFFERS: 'oro-offer',
	BANNERS: 'banners',
	REPORTS: 'reports',
	PAYMENTS: 'payments',
	REFUNDS: 'refunds',
	FUND_TRANSFER: 'fund-transfer',
	PAYOUTS: 'payouts',
	 FETCH_CLOSURE_AMOUNT: 'fetch-closure-amount',
	PENDING_ONBOARDING: "user-pending"
 };

 export const getPaisaRoute = () => {
	const userPermissions = getValueFromLocalStorage('userPermissions') ?? [];
	if (userPermissions?.includes(permissions.paisa.payments.permission[0])) {
		return `/${SIDEBAR_CONSTANTS.PAYMENTS}`;
	}
	if (userPermissions?.includes(permissions.paisa.refunds.permission[0])) {
		return `/${SIDEBAR_CONSTANTS.REFUNDS}`;
	}
	if (
		userPermissions?.includes(permissions.paisa.fund_transfer.permission[0])
	) {
		return `/${SIDEBAR_CONSTANTS.FUND_TRANSFER}`;
	}
	if (userPermissions?.includes(permissions.paisa.payout.permission[0])) {
		return `/${SIDEBAR_CONSTANTS.PAYOUTS}`;
	}
	if (
		userPermissions?.includes(
			permissions.paisa.fetchClosure.fetch_closure_amount[0]
		)
	) {
		return `/${SIDEBAR_CONSTANTS.FETCH_CLOSURE_AMOUNT}`;
	}
	return '';
};

 export default SIDEBAR_CONSTANTS;