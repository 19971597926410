import { getValueFromLocalStorage, clearAllLocalStorage, addToLocalStorage } from "./local-storage";
import { deleteAllCookies } from "./cookies";

const verifyGoogleAuth = async (token) => {
	let response = await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/auth/verify-google`, {
		method: 'POST',
		headers: {
			"Authorization": `JWT ${token}`,
			"client-id": process.env.REACT_APP_CLIENT_ID,
		},
	});
	return await response.json();
};

const refetchToken = async () => {
	let response = await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/auth/refresh-token`, {
		method: 'POST',
		headers: {
			"refresh-token": `${getValueFromLocalStorage('refreshToken')}`,
		},
	});
	return await response.json();
};

const logout = async () => {
	let response = await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/auth/logout`, {
		method: 'POST',
		headers: {
			"Authorization": `JWT ${getValueFromLocalStorage('token')}`,
			"refresh-token": `${getValueFromLocalStorage('refreshToken')}`,
		},
	});
	return await response.json();
};

const verifyToken = async() => {
	let response = await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/auth/verify-token`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${getValueFromLocalStorage('token')}`,
        },
      })
	const error =  await response.json();
	return error;
}

const authVerification = async() => {
	await verifyToken()
      .then((res) => {
		const userPermissions = res?.user?.permissions;

		if (getValueFromLocalStorage('userPermissions') === null) {
			addToLocalStorage('userPermissions', userPermissions)
		}

        if (res?.status !== 200) {
			logout().then(() => {
				deleteAllCookies();
				clearAllLocalStorage();
				window.location.assign('/');
			  });
			return;
        }
      })
}

export { verifyGoogleAuth, refetchToken, logout, verifyToken, authVerification };
