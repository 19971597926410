import Clarity from '@microsoft/clarity';

// Initialize Clarity with basic configuration
export const initClarity = () => {
	try {
		Clarity.init(process.env.REACT_APP_ADMIN_CLARITY_PROJECT_ID || '');
	} catch (error) {
		console.error('Clarity initialization failed:', error);
	}
};

// helps to identify a user once we have logged in
export const identifyClarity = (userEmail, userName) => {
	try {
		Clarity.identify(userEmail, undefined, undefined, userName);
	} catch (error) {
		console.error('Clarity user identification failed:', error);
	}
};